<template>
  <div id="app"> 
   <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
@import url(./assets/css/reset.css);

.home{
    background:linear-gradient(to bottom,#4D49FF,#C5E8FF);
    padding-bottom: 140px;
    min-height: 89vh;
}
.container{
    width: 690px;
    padding: 0px 30px;
}
</style>
