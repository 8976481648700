
import Vue from "vue";
import Router from "vue-router"

Vue.use(Router)

const router =  new Router({
    routes:[
        {
            path:'/',
            redirect:'/Home',
            name:'Home',
            // component : () => import('@/components/Home')  路由懒加载方式
            //component : resolve => require(['@/components/Home'],resolve)  异步组件加载方式
            component : () => import('@/pages/Home'),
            meta: {
                title: 'หน้าแรก'//首页
            }
        },
        {
            path:'/Home',
            name:'Home',
            component : () => import('@/pages/Home'),
            meta: {
                title: 'หน้าแรก'//首页
            }
        },
        {
            path:'/Good',
            name:'Good',
            component : resolve => require(['@/pages/Good'],resolve),
            meta: {
              title: 'เติมเงิน'//储值
            }
        },
        {
            path:'/Help',
            name:'Help',
            component : resolve => require(['@/pages/Help'],resolve),
            meta: {
              title: 'ช่วยเหลือ'//帮助中心
            }
        },
        {
            path:'/CommonProblem',
            name:'CommonProblem',
            component : resolve => require(['@/pages/CommonProblem'],resolve),
            meta: {
              title: 'ปัญหาที่พบบ่อย'//常见问题
            }
        },
        {
            path:'/Group',
            name:'Group',
            component : resolve => require(['@/pages/Group'],resolve),
            meta: {
              title: 'เข้าร่วม'//加入群组
            }
        },
        {
            path:'/Connect',
            name:'Connect',
            component : resolve => require(['@/pages/Connect'],resolve),
            meta: {
              title: 'ติดต่อเจ้าหน้าที่'//联系方式
            }
        },
        {
            path:'/Privacy',
            name:'Privacy',
            component : resolve => require(['@/pages/Privacy'],resolve),
            meta: {
              title: 'นโยบายความเป็นส่วนตัว'//隐私协议
            }
        },
        {
            path:'/CyberSecurity',
            name:'CyberSecurity',
            component : resolve => require(['@/pages/CyberSecurity'],resolve),
            meta: {
              title: 'นโยบายความปลอดภัย'//网页安全中心
            }
        },
        {
            path:'/UpdateLogs',
            name:'UpdateLogs',
            component : resolve => require(['@/pages/UpdateLogs'],resolve),
            meta: {
              title: 'ข่าวประกาศ'//更新日志
            }
        },
        {
          path:'/GameReport',
          name:'GameReport',
          component : resolve => require(['@/pages/GameReport'],resolve),
          meta: {
            title: 'ข่าวประกาศ'//游戏公告
          }
      }
    ]
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'หน้าแรก';
    next();
});
export default router