import Vue from 'vue';
import { Lazyload,Button,Dialog,Collapse, CollapseItem,Swipe, SwipeItem,Tabbar, TabbarItem,Toast,CountDown  } from 'vant';

Vue.use(Lazyload, {
    lazyComponent: true,
  });
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);
Vue.use(CountDown);

  