import Vue from 'vue'
import App from './App.vue'
import '../plugins/element.js'
import axios from 'axios'
import router from './router'
import service from '../service.js'
import '../plugins/vant.js'
import 'amfe-flexible'


Vue.prototype.axios = axios  //挂载到原型，可以在全局使用
Vue.config.productionTip = false
Vue.prototype.service = service


new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	},
}).$mount('#app')

//全局 引用element插件
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);