import axios from 'axios'

const service = axios.create({
    // baseURL: 'local_api', // 本地环境
    // baseURL: '/test_api', // 测试环境
    // baseURL: '/sandbox_api', // 
    baseURL: 'api', // 生产环境
    
    withCredentials: true,
    responsetype:'json',
})

service.defaults.headers.post['Content-Type'] = 'multipart/form-data';

// 添加请求拦截器
service.interceptors.request.use((config) => {
    // config.headers["Access-Control-Allow-Origin"] ="*";
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // 对响应数据做些什么
    // let { status, message } = response.data
    // if(status !== 200) { 
    //     Message({message: message || 'error', type: 'warning'})
    // }
    return response
}, (error) => {
    return Promise.reject(error)
})

export default service